//import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function HeaderBRK(props) {
  const navigate = useNavigate();

  // const cart = useSelector((state) => state.cart);
  return (
    <div
      className={['flex-container-header-client-mobile', props.classes].join(
        ' '
      )}
    >
      <img
        onClick={() => navigate('/')}
        className="logo"
        src={
          props.location === 'home' || props.location === 'product-page'
            ? '/images/logo_white.svg'
            : '/images/logo_black.svg'
        }
        // onClick={props.goHomeHandleClick}
        width="80px"
        height="35px"
      />
      <div className="flex-container-icons-mobile">
        {/* <img src="/images/bag.svg" className="icon-menu-mobile-bag" alt="bag" /> */}

        <img
          className="icon-menu-mobile"
          src={
            props.location === 'home' || props.location === 'product-page'
              ? '/images/hamburguer_white.svg'
              : '/images/hamburguer.svg'
          }
          onClick={props.openMobileMenu}
          width="80px"
          height="35px"
        />
      </div>

      {/* <img className="icon-user-menu-mobile" src="/images/user-bold.svg" /> */}
    </div>
  );
}

export default HeaderBRK;
