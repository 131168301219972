// function AdminHeaderMobile(props) {
//   return (
//     <>
//       <div className="flex-container-header-admin-mobile">
//         <img
//           className="logo"
//           src="/images/bereallykeen_white.svg"
//           onClick={props.goHomeHandleClick}
//           width="80px"
//           height="35px"
//         />

//         <img
//           className="icon-menu-mobile"
//           src="/images/hamburguer_white.svg"
//           onClick={props.openMobileMenu}
//         />

//         {/* <img className="icon-user-menu-mobile" src="/images/user-bold.svg" /> */}
//       </div>

//     </>
//   );
// }

// export default AdminHeaderMobile;

import React, { useEffect, useState } from 'react';

function AdminHeaderMobile(props) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = window.innerHeight;

      if (scrollTop > 50 && scrollTop + clientHeight < scrollHeight - 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className="flex-container-header-admin-mobile">
        <img
          className="logo"
          src="/images/logo_white.svg"
          onClick={props.goHomeHandleClick}
          width="80px"
          height="35px"
        />

        <img
          className="icon-menu-mobile"
          src="/images/hamburguer_white.svg"
          onClick={props.openMobileMenu}
        />

        {/* <img className="icon-user-menu-mobile" src="/images/user-bold.svg" /> */}
      </div>
      {isScrolled && !props.isMobileMenuOpen && (
        <>
          <div className="floating-mobile-menu" onClick={props.openMobileMenu}>
            <h3>Open menu</h3>
          </div>

          <div className="scroll-to-top" onClick={scrollToTop}>
            <span className="arrow-up">↑</span>
          </div>
        </>
      )}
    </>
  );
}

export default AdminHeaderMobile;
