//import { useSelector } from 'react-redux';

function HeaderBRKOrderPage(props) {
  // const cart = useSelector((state) => state.cart);
  return (
    <div
      className={[
        'flex-container-header-client-mobile',
        // 'sticky-header',
        props.classes,
      ].join(' ')}
    >
      <img
        className="logo"
        src="/images/logo_black.svg"
        // onClick={props.goHomeHandleClick}
        width="80px"
        height="35px"
      />
      <div className="flex-container-icons-mobile">
        <div
          className="bag-icon-container"
          onClick={() => props.openSummaryDrawer()}
        >
          <img
            src="/images/bag.svg"
            className="icon-menu-mobile-bag"
            alt="bag"
          />
          {props.cart && props.cart.length > 0 && <div className="badge"></div>}
        </div>

        {/* <img
                src="/images/icon-alert.svg"
                className="icon-menu-mobile-bag"
                alt="bag"
                width="80px"
                height="35px"
            /> */}

        <img
          className="icon-menu-mobile"
          src="/images/hamburguer.svg"
          onClick={props.openMobileMenu}
          width="80px"
          height="35px"
        />
      </div>

      {/* <img className="icon-user-menu-mobile" src="/images/user-bold.svg" /> */}
    </div>
  );
}

export default HeaderBRKOrderPage;
