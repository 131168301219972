function LoadingCapsulesImages() {
  return (
    <div className="loading-capsules-img">
      {/* <img
        src="/images/clubs1.jpg"
        alt="image-loading-capsules"
        className="loading-capsules-img"
      /> */}
      <img src="/images/logo_white.svg" className="keen" />
      {/* <h1>AW24 Collection</h1> */}
    </div>
  );
}

export default LoadingCapsulesImages;
