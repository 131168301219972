import React from 'react';
import Footer from '../Footer';
import { capFirstLetter } from '../../functions/cap-first-letter';

// const techList = [
//   'Breathable',
//   'UV-protection',
//   '4-way stretch',
//   'Water resistant',
//   'Hydrophilic',
//   'Antibacterial',
//   'Fresh control',
//   'Perfect fit',
//   'Quick drying',
//   'Water repelent',
//   'Recycled yarn',
//   'Feather weight',
//   'Soft',
//   'Moisture management',
//   'Windproof',
//   'Waterproof',
//   'Thermal',
//   'Hypoallergenic',
//   'Sustainable manufacturing'
// ];

function ImageBigScale(props) {
  const TECHS = {
    Breathable: 'breathable',
    'UV-protection': 'uv-protection',
    '4-way stretch': '4-way-stretch',
    'Perfect fit': 'Perfect-fit',
    'Quick drying': 'Quick-drying',
    'Recycled yarn': 'Recycled',
    Soft: 'Soft',
    Antibacterial: 'antibacterial',
    // '': 'coolmax',
    'Feather weight': 'Feather-weight',
    // '': 'Sun-cream-resistant',
    Thermal: 'thermal',
    'Fresh control': 'Fresh-control',
    Waterproof: 'water-repellent',
    // 'Waterproof': 'Water-resistant',
    Windproof: 'windproof',

    // Add more entries as needed
  };

  const garmentTypeTranslations = {
    Polo: 'Polo Shirt',
    'Polo manga larga': 'Long Sleeve Polo Shirt',
    'Pantalón largo': 'Pants',
    'Pantalón corto': 'Shorts',
    'Mid-layer': 'Mid-layer',
    'Mid-layer sin manga': 'Sleeveless Mid-layer',
    Jersey: 'Jersey',
    Falda: 'Skirt',
    Vestido: 'Dress',
    Cortavientos: 'Windstopper',
    Abrigo: 'Coat',
    Chaqueta: 'Jacket',
    Camiseta: 'T-Shirt',
    Sudadera: 'Hoodie',
    'Calcetín corto': 'Short Socks',
    'Calcetín largo': 'Long Socks',
    Manguito: 'Sleeve',
    Gorra: 'Cap',
    Cinturón: 'Belt',
    'Chaqueta acolchada': 'Padded Jacket',
    Chaleco: 'Vest',
    'Chaleco reversible cortaviento': 'Reversible windstopper Vest',
    'Chaqueta reversible cortaviento': 'Reversible windstopper Jacket',
  };

  const technologies = (t) => {
    let tech = TECHS[t];

    if (tech) {
      return (
        <div className="individual-tech">
          <img src={`/images/tech/${tech}.svg`} className="img-tech" />
          <h2>{capFirstLetter(tech)}</h2>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="ibg-logo">
        <img className="logo" src="/images/logo_black.svg" />
      </div>
      <div className="ibg-box">
        <div className="exit-container">
          <div className="exit-2-container" onClick={props.closeImageBigScale}>
            <img
              src="/images/exit-arrow.svg"
              className="arrow-exit"
              alt="arrow-exit"
            />
            <p>Go back</p>{' '}
          </div>

          <h2>AW24 Collection</h2>
        </div>
        <hr className="horizontal-line" />
        <div className="ibg-card-box">
          <div className="image-block">
            <div className="box-container-img-big-scale">
              {props.product.image.url && (
                <img
                  onClick={props.closeImageBigScale}
                  src={`${props.product.image.url}`}
                  alt="product-img"
                  className="image-big-scale"
                />
              )}
            </div>
          </div>
          <div className="info-block">
            <div className="ibg-title">
              <h1>{props.gender.toUpperCase()}</h1>
              <h2>{props.capsules[props.currentCapsule].capsuleName}</h2>
            </div>
            <hr className="horizontal-line" />
            <h2 className="ibg-italic">
              {garmentTypeTranslations[props.product.type] || ''}
            </h2>
            <div className="ibg-features">
              <div className="ibg-name-price">
                <h2 className="ibg-name">{props.product.name}</h2>
                <h2 className="ibg-name">{`${props.product.price} €`}</h2>
              </div>

              <p>{props.product.composition}</p>
              <p>{props.product.description}</p>

              <p>{`Color: ${props.product.color}`}</p>
              <p>{`SRP. ${props.product.srp} €`}</p>
              <p>{`Ref. ${props.product.reference}`}</p>
            </div>
            {/* <div className="available-sizes">
              <h2 className="ibg-italic">Available on sizes S-XXL</h2>
            </div> */}
          </div>
        </div>

        {props.product.tech && props.product.tech.length > 0 && (
          <React.Fragment>
            {/* <hr className="horizontal-line" /> */}
            <div className="product-techs-box">
              <div className="flex-container-title-detail">
                <div>
                  <p>Fabrics</p>
                </div>

                <div className="thin-rectangle"></div>
              </div>
              <h1>TECH +</h1>
              <p>
                This product is crafted with advanced technologies, ensuring
                unparalleled quality and performance in its fabric,
                incorporating features such as:{' '}
              </p>

              <div className="product-techs">
                {props.product.tech.map((t, index) => (
                  <React.Fragment key={index}>{technologies(t)}</React.Fragment>
                ))}
              </div>
            </div>
          </React.Fragment>
        )}

        {/* <div className="flex-container-image-big-scale-info">
        <div className="data-info">
          <h2>
            <b>Description:</b>
            {` ${props.product.description}`}
          </h2>
          <h2>
            <b>Composition:</b>
            {` ${props.product.composition}`}
          </h2>
          <h2>
            <b>Color:</b>
            {` ${props.product.color}`}
          </h2>
        </div>
        <div>
          <h2>
            <b>Price:</b>
            {` ${props.product.price} €`}
          </h2>
          <h2>
            <b>SRP:</b>
            {` ${props.product.srp} €`}
          </h2>
        </div>
      </div> */}
        {/* <div className="box-container-img-big-scale">
        {props.product.image.url && (
          <img
            onClick={props.closeImageBigScale}
            src={`${props.product.image.url}`}
            alt="product-img"
            className="image-big-scale"
          />
        )}
      </div> */}
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ImageBigScale;
