//import { useSelector } from 'react-redux';

function HeaderLogin(props) {
  // const cart = useSelector((state) => state.cart);
  return (
    <div
      className={['flex-container-header-client-mobile', props.classes].join(
        ' '
      )}
    >
      <img
        className="logo"
        src="/images/logo_black.svg"
        width="80px"
        height="35px"
      />
      <img
        onClick={() => props.sigInMsg()}
        className="icon-menu-mobile"
        src="/images/hamburguer.svg"
        width="80px"
        height="35px"
      />

      {/* <img className="icon-user-menu-mobile" src="/images/user-bold.svg" /> */}
    </div>
  );
}

export default HeaderLogin;
